import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { useSiteContext } from "./components/common/SiteContext"
import MainPage from "./MainPage"
import { MarketStatsMarket } from "@organic-return/foundation-react/src/components/MarketStats/MarketStats"
import { CommunityLayoutStandard } from "@organic-return/foundation-react/src/components/Community/CommunityLayoutStandard"
import { IFeaturedContentOption } from "@organic-return/foundation-react/src/components/Blades/FeaturedWithDropdownContent"
import axios from "axios"
import { getRecaptchaToken, getSegmentAnonymousId, registerSegmentActivity } from "./lib/utils"
import { IImageWithOverlappedTextBladeProps } from "@organic-return/foundation-react/src/components/Blades/ImageWithOverlappedTextBlade"
import truncate from "truncate-html"

export const query = graphql`
  query($nodeID: String!) {
    agilityCommunity(id: { eq: $nodeID }) {
      gallery {
        media {
          url
          metaData {
            pixelHeight
            pixelWidth
          }
        }
        galleryID
      }
      seo {
        metaKeywords
        metaDescription
      } 
      customFields {
        name
        bladeTitle
        description               
        marketReportBGImage {          
          url
        }        
        listingsSearchUrl {
          href
        }
        centerBladeTitle
        centerBladeContent
        centerBladeBackgroundImage {          
          url
        }
        centerBladeForegroundImage {          
          url
        }
        centerBladeCTA {
          href
          text
          target
        }
      }
      marketStatsJSON
      listings {
        id
        baths
        address {
          city
          county
          postal
          text
        }
        beds              
        flags
        mlsID
        price
        sqft
        status                
        defaultImage {
          dimensions
          url
        }
        path
      }
      lifestyle {
        image {
          url
          height
          width
        }
        text
        title
      }
    }
    config: agilitySiteConfiguration {
      customFields {        
        listingSearchPath
      }
    }
  }
`
const CommunityPage = ({ data, location }: any) => {
  const siteContext = useSiteContext()
  const community = data.agilityCommunity

  const Layout = siteContext.communityPageLayout ?? CommunityLayoutStandard

  const pageSize = siteContext.communityLayoutProps?.listingsResultPageSize ?? 20



  let markets: MarketStatsMarket[] | undefined = undefined
  const parsedMarkets = JSON.parse(community.marketStatsJSON)?.markets
  if (parsedMarkets) {
    markets = parsedMarkets
  }

  let lifestyleItems: IFeaturedContentOption[] | undefined = undefined
  lifestyleItems = community.lifestyle?.map((item: any) => {
    return {
      label: item.title,
      content: item.text,
      image: {
        url: item.image?.url
      }
    }
  })


  const activeListings = community.listings?.filter(((listing: any) => {
    return listing.status != "Closed"
  }))

  const searchProps = siteContext.communityLayoutProps?.listingsSearchProps ?? siteContext.communitySearchProps
  const cardProps = siteContext.communityLayoutProps?.listingsResultCardsProps ?? siteContext.globalListingCardProps

  if (searchProps && !searchProps.mapClick) {
    searchProps.mapClick = item => navigate(item.path)
  }

  let galleryImages = []
  if (community.gallery?.media) {
    galleryImages = community.gallery.media.map((media: any) => {
      return media.url
    })
  }

  const communityRequestInformationClick = async (formInput: any) => {
    const recaptchaToken = await getRecaptchaToken("about_community");
    await axios({
      url: process.env.LEAD_CREATE_API_ENDPOINT,
      method: "POST",
      data: {
        recaptchaToken,
        formValues: formInput,
        propertyInfo: {
          createdFrom: `About Community - ${community.customFields?.name}`,
        },
        segmentAnonymousId: getSegmentAnonymousId(),
      },
    })
    registerSegmentActivity(`Community Request Information Form Submission`, formInput)
  }

  if (siteContext!.communityLayoutProps && siteContext!.communityLayoutProps!.contactUsFormProps) {
    siteContext!.communityLayoutProps!.contactUsFormProps!.clickHandler = communityRequestInformationClick;
  }
  if (siteContext!.communityLayoutProps && siteContext!.communityLayoutProps!.requestInfoFormProps) {
    siteContext!.communityLayoutProps!.requestInfoFormProps!.clickHandler = communityRequestInformationClick;
  }

  let centerBlade: IImageWithOverlappedTextBladeProps | undefined = undefined;
  if (community.customFields.centerBladeBackgroundImage?.url &&
    community.customFields.centerBladeForegroundImage?.url &&
    community.customFields.centerBladeTitle &&
    community.customFields.centerBladeContent) {

    const { centerBladeBackgroundImage, centerBladeForegroundImage, centerBladeTitle, centerBladeContent, centerBladeCTA } = community.customFields
    centerBlade = {
      backgroundImage: {
        url: centerBladeBackgroundImage.url,
        dimensions: (centerBladeBackgroundImage.width && centerBladeBackgroundImage.height) && [centerBladeBackgroundImage.width, centerBladeBackgroundImage.height]
      },
      leadImage: {
        url: centerBladeForegroundImage.url,
        dimensions: (centerBladeForegroundImage.width && centerBladeForegroundImage.height) && [centerBladeForegroundImage.width, centerBladeForegroundImage.height]
      },
      title: centerBladeTitle,
      text: centerBladeContent,
      callToAction: centerBladeCTA?.href && centerBladeCTA,
    }
  }


  var pageTitle = community.customFields?.name ?? "Community Page";
  var pageDescripton = data.agilityCommunity.seo?.metaDescription ?? truncate(community.customFields.description ?? "", 297, { stripTags: true });
  var pageKeywords = data.agilityCommunity.seo?.metaKeywords;

  return (
    <MainPage
      headerProps={{ gradient: true, ...siteContext.headerProps }}
      seoProps={{ title: pageTitle, description: pageDescripton, keywords: pageKeywords, location: location }}
      className="community-page"
    >
      <Layout
        heroCarouselImagesUrls={galleryImages}
        title={community.customFields.name}
        isTitleInHeading={false}
        description={community.customFields.description}
        lifestyleItems={lifestyleItems}
        listings={activeListings}
        listingsResultPageSize={pageSize}
        linkFunc={props => {
          return (
            <Link
              to={props.href ?? ""}
              className={`text-inherit ${props.className}`}
              key={props.key}
              title={props.title}
            >
              {props.children}
            </Link>
          )
        }}
        listingsResultCardsProps={cardProps}
        listingsSearchUrl={community.customFields.listingsSearchUrl?.href}
        markets={markets}
        marketReportBgImageUrl={community.customFields.marketReportBGImage?.url}
        centerBlade={centerBlade}
        {...siteContext.communityLayoutProps}
      >
      </Layout>
    </MainPage>
  )
}

export default CommunityPage
